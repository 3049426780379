import chroma from 'chroma-js';

function hexToRgb(color: string) {
  return chroma(color).rgb().join(', ');
}

function rgba(color: string, opacity = 1) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export {hexToRgb, rgba}