import React from "react";

const Header: React.FC<any> = ({ quizInfo }) => {
  return (
    <div className="w-full bg-background-100 flex justify-center items-center xs:px-20 px-10 h-16">
      <div className="w-full max-w-[1392px] flex justify-start gap-2">
        <div className="logo text-primary-100">QUIZ</div>
        <div className="basic text-secondary-100">
          prepared by {quizInfo?.user?.fullname}
        </div>
      </div>
    </div>
  );
};

export default Header;
