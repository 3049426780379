import React from "react";
import classNames from "classnames";
interface IQuizAnswer {
    binaryValue: string;
    answerDesc: string;
    chooseAnswer: () => void;
    chosenAnswer: string;
}
const QuizAnswer: React.FC<IQuizAnswer> = ({binaryValue, answerDesc, chooseAnswer,  chosenAnswer}) => {
    return (
        <div className="relative cursor-pointer quiz-ans-container">
            <div className="sm:w-[420px] w-[300px] sm:h-[60px] h-[80px] absolute z-10 border border-primary-100 border-opacity-20 -top-2 -right-2 rounded"></div>
            <div 
                className={classNames(
                    [
                        "sm:w-[420px] w-[300px] sm:h-[60px] h-[80px] px-4 quiz-ans flex items-center justify-center relative z-50 border border-primary-100 rounded text-center",
                        {'bg-background-200': chosenAnswer !== binaryValue},
                        {'bg-secondary-100': chosenAnswer === binaryValue}
                    ]
                )
                } 
                onClick={chooseAnswer}
            >
                {answerDesc}
            </div>
        </div>
    )
};

export default QuizAnswer;