import styled from 'styled-components';

import variableStyles from '../../../../../../common/styles/variable-styles';
import { rgba } from '../../../../../../common/utils/color';
import Icon from '../../../../../../components/Icon';
import type { IIconProps } from '../../../../../../components/Icon';
export const StyledBriefingItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 calc(1 / 3 * 100%);
  padding: 15px 0px;
  border-right: 2px solid ${rgba(variableStyles.greyColor2, 0.2)};
  &:last-of-type {
    border-right: none;
  }
  @media screen and (max-width: 768px) {
    border-right: none;
    border-bottom: 1px solid ${rgba(variableStyles.greyColor2, 0.2)};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 15px 20px;
    &:last-of-type {
      border-bottom: none;
    }
  }
`;

export const StyledIcon = styled(Icon)<IIconProps>`
    color: ${variableStyles.primaryInkGrey};
    font-size: 32px;
`;

export const StyledTextContainer = styled.div.attrs(props => ({
    className: 'caption',
}))`
`;
