import styled from 'styled-components';
import Icon, { IIconProps } from '../../../../components/Icon';
import variableStyles from '../../../../common/styles/variable-styles';
import { rgba } from '../../../../common/utils/color';
const containerDiameter = '47px';
export const StyledPagerContainer = styled.div.attrs(props => ({
    className: props.className,
}))`
    height: ${containerDiameter};
    width: ${containerDiameter};
    display: flex;
    flex: 0 0 ${containerDiameter};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    border-radius: 100%;
    background-color: ${rgba(variableStyles.greyColor3, 0.1)};
    cursor: pointer;
`;
export const StyledIcon = styled(Icon)<IIconProps>`
    color: ${variableStyles.primaryInkGrey};
    font-size: 26px;
`;
