import axios from "axios";

const HOST_URL = process.env.REACT_APP_PUBLIC_HOST_API;

export const getClient = (id: string) => {
  return axios.get(`${HOST_URL}/customers/client-quiz/${id}`);
};

export const submitQuiz = (id: string, data: any) => {
  return axios.put(`${HOST_URL}/customers/client-quiz/${id}/submit`, data);
};
