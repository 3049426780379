import React from "react";
import { Divider } from "antd";
import ButtonNormal from "../../../../components/ButtonNormal";
import Icon from "../../../../components/Icon";
interface IFinish {
  retake: () => void;
  quizInfo: any;
}
const Finish: React.FC<IFinish> = ({ retake, quizInfo }) => {
  return (
    <div className="pt-12 px-10">
      <div className="max-w-[1392px] m-auto">
        <div className="flex flex-col">
          <div className="flex gap-6 items-center">
            <Icon
              icon="icon icon-thumb-up"
              className="text-success-100 text-[52px]"
            />
            <div className="heading">
              <span className="logo">Thank you,</span> {quizInfo.fullname}, you
              have answered all the questions.
            </div>
          </div>
          <Divider className="border-primary-100 border-opacity-10" />
          <div className="flex gap-6 items-center">
            <Icon
              icon="icon icon-star"
              className="text-primary-100 text-[24px]"
            />
            <div className="basic font-semibold">
              We&#39;re glad that you have completed the quiz.&nbsp; Please get
              in touch with{" "}
              <span className="text-secondary-100">
                {quizInfo?.user?.fullname}
              </span>{" "}
              for the report.
            </div>
          </div>
          <div className="flex gap-6 items-center">
            <Icon
              icon="icon icon-star"
              className="text-primary-100 text-[24px]"
            />
            <div className="basic font-semibold">
              We have recorded your answers and you may close the tab now !
            </div>
          </div>
          <div className="flex gap-4 mt-8">
            <ButtonNormal className="w-40" onClick={retake}>
              Retake
            </ButtonNormal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finish;
