import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Landing from "./components/Landing";
import TakeQuiz from "./components/TakeQuiz";
import Finish from "./components/Finish";
import { useMatch } from "react-router-dom";
import { getClient, submitQuiz } from "../../services/quiz";
import posthog from "posthog-js";
const ClientQuizPage: React.FC = () => {
  const match = useMatch("/:id");
  const [quizInfo, setQuizInfo] = useState<any>();
  const [step, setStep] = useState<"LANDING" | "TAKE_QUIZ" | "FINISH">(
    "LANDING"
  );
  const [finishing, setFinishing] = useState(false);

  const onFinish = async (payload: any) => {
    try {
      if (match?.params.id) {
        posthog.capture("fiscal-quiz-completed", {
          sent_to: quizInfo?.fullname,
          sent_by: quizInfo?.user?.fullname,
          url_code: quizInfo?.clientQuizCode,
        });
        setFinishing(true);
        await submitQuiz(match?.params.id || "", payload);
        setFinishing(false);
        setStep("FINISH");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const curStep = () => {
    if (step === "LANDING") {
      return (
        <Landing quizInfo={quizInfo} takeQuiz={() => setStep("TAKE_QUIZ")} />
      );
    } else if (step === "TAKE_QUIZ") {
      return (
        <TakeQuiz
          quizInfo={quizInfo}
          toFinish={onFinish}
          finishing={finishing}
        />
      );
    } else {
      return <Finish quizInfo={quizInfo} retake={() => setStep("TAKE_QUIZ")} />;
    }
  };

  const handleGetQuizInfo = async (id: string) => {
    try {
      const res = await getClient(id);
      setQuizInfo(res?.data);
      if (res?.data?.clientQuizPdfKey) {
        setStep("FINISH");
      }
    } catch (error) {
      console.log("xxx error:", error);
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (match?.params?.id) handleGetQuizInfo(match?.params?.id);
  }, [match?.params.id]);

  return (
    <>
      <Header quizInfo={quizInfo} />
      <div className="min-h-screen w-full bg-background-200">{curStep()}</div>
    </>
  );
};

export default ClientQuizPage;
