import React from 'react';
import ReactDOM from 'react-dom/client';
import { PostHogProvider} from 'posthog-js/react'
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { GlobalStyle } from './common/styles/global-styles';
import ClientQuiz from './container/ClientQuiz';
import NotFoundPage from './container/404';
const router = createBrowserRouter([
  {
    path: '/',
    element: <NotFoundPage />
  },
  {
    path: '/:id',
    element: <ClientQuiz />
  }
]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}
root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >

    <GlobalStyle />
    <RouterProvider router={router}/>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
