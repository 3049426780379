import React, { useState, useMemo } from "react";
import quizQn from "../../../../data/quiz.json";
import ProgressBar from "../../../../components/ProgressBar";
import QuizBriefing from "./components/QuizBriefing";
import { StyledPagerContainer, StyledIcon } from "./styled";
import QuizQuestion from "./components/QuizQuestion";
import ButtonNormal from "../../../../components/ButtonNormal";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";

const LETTERS = {
  ISTJ: [60, 10, 0, 10], // Knight
  ISFJ: [38, 5, 32, 5], // Knight-Healer
  ESFJ: [38, 32, 5, 5], // Knight-Explorer
  ESTJ: [38, 5, 5, 32], // Knight-Wizard
  ISTP: [5, 38, 5, 32], // newly fixed at 2nd July 2024 => Explorer-Wizard
  ISFP: [32, 38, 5, 5], // newly fixed at 2nd July 2024 => Explorer-Knight
  ESFP: [5, 38, 32, 5], // newly fixed at 2nd July 2024 => Explorer-Healer
  ESTP: [10, 60, 10, 0], // newly fixed at 2nd July 2024 => Explorer
  INFP: [10, 0, 60, 10], // Healer
  INFJ: [32, 5, 38, 5], // Healer-Knight
  ENFP: [5, 32, 38, 5], // Healer-Explorer
  ENFJ: [5, 5, 38, 32], // Healer-Wizard
  INTP: [5, 5, 32, 38], // newly fixed at 2nd July 2024 => Wizard-Healer
  INTJ: [0, 10, 10, 60], // newly fixed at 2nd July 2024 => Wizard
  ENTP: [5, 32, 5, 38], // newly fixed at 2nd July 2024 => Wizard-Explorer
  ENTJ: [32, 5, 5, 38], // newly fixed at 2nd July 2024 => Wizard-Knight
};
interface IPagerLeft {
  curPage: number;
  pageProgress: () => void;
  // location: 'SIDE' | 'BOTTOM';
}
const PagerLeft: React.FC<IPagerLeft> = ({ curPage, pageProgress }) => {
  return (
    <StyledPagerContainer
      onClick={() => {
        if (curPage > 1) {
          pageProgress();
        }
      }}
      className={classNames([
        // {"sm:flex hidden": location === 'SIDE'},
        // {"sm:hidden flex mt-10": location !== 'SIDE'},
        { "cursor-not-allowed": curPage === 1 },
      ])}
    >
      <StyledIcon icon="icon icon-arrow-left" />
    </StyledPagerContainer>
  );
};
interface IPagerRight {
  disabled: boolean;
  pageProgress: () => void;
  // location: 'SIDE' | 'BOTTOM';
}
const PagerRight: React.FC<IPagerRight> = ({ disabled, pageProgress }) => {
  return (
    <StyledPagerContainer
      onClick={() => {
        if (!disabled) {
          pageProgress();
        }
      }}
      className={classNames([
        // {"sm:flex hidden": location === 'SIDE'},
        // {"sm:hidden flex mt-10": location !== 'SIDE'},
        { "cursor-not-allowed": disabled },
      ])}
    >
      <StyledIcon icon="icon icon-arrow-right" />
    </StyledPagerContainer>
  );
};
interface ITakeQuiz {
  toFinish: any;
  finishing: boolean;
  quizInfo: any;
}
const TakeQuiz: React.FC<ITakeQuiz> = ({ toFinish, finishing, quizInfo }) => {
  const [quizData, setQuizData] = useState<IQuiz[]>(quizQn.clientQuiz);
  const [quizPage, setQuizPage] = useState<number>(1);
  const handleChooseAnswer = (id: number, binaryValue: string) => {
    const clonedQuestions: IQuiz[] = JSON.parse(JSON.stringify(quizData));
    const curQuestion: IQuiz = clonedQuestions.find(
      (qn) => qn.id === id
    ) as IQuiz;
    const chosenAnswerIndex = curQuestion.options.findIndex(
      (opt) => opt.binaryValue === binaryValue
    );
    if (curQuestion.chosenAnswer !== binaryValue) {
      let point;
      if (curQuestion?.id % 2 === 0) {
        point = chosenAnswerIndex === 0 ? 1 : -1;
      } else {
        point = chosenAnswerIndex === 0 ? -1 : 1;
      }
      curQuestion.chosenAnswer = binaryValue;
      curQuestion.point = {
        ...curQuestion.point,
        [curQuestion.category]: point,
      };
      setQuizData(clonedQuestions);
      if (id !== 20) {
        setTimeout(() => {
          setQuizPage(quizPage + 1);
        }, 400);
      }
    }
  };
  const computeIndexScores = (totalPointsByCategory: {
    EI: number;
    SN: number;
    TF: number;
    JP: number;
  }) => {
    let tendencyScores = {
      EI: [0, 0],
      SN: [0, 0],
      TF: [0, 0],
      JP: [0, 0],
    };
    // LR = R - L (e.g. EI = I - E)
    // L + R = 5
    // L = [(L + R) - (R - L)] / 2 => (5 - LR) / 2
    // R = [(L + R) + (R - L)] / 2 => (5 + LR) / 2
    Object.entries(totalPointsByCategory).forEach((entry) => {
      const [category, value] = entry;
      tendencyScores[category as keyof typeof tendencyScores] = [
        (5 - value) / 2,
        (5 + value) / 2,
      ];
    });
    const indexScores = {
      RTI: tendencyScores.EI[0] + tendencyScores.JP[1], // E + P
      FOI: tendencyScores.SN[1] + tendencyScores.JP[0], // N + J
      SRI: tendencyScores.EI[1] + tendencyScores.TF[0], // I + T
      SPI: tendencyScores.SN[0] + tendencyScores.JP[0], // S + J
    };
    return indexScores;
  };
  const handleShowResult = () => {
    let qnsAnswered: IQuiz[] = [];
    let qnsNotAnswered: IQuiz[] = [];
    qnsAnswered = quizData.filter((qn) => qn.chosenAnswer !== "");
    qnsNotAnswered = quizData.filter((qn) => qn.chosenAnswer === "");
    if (qnsAnswered.length < quizData.length) {
      setQuizPage(qnsNotAnswered?.[0]?.id);
    } else {
      const totalPointsByCategory = {
        EI: 0,
        SN: 0,
        TF: 0,
        JP: 0,
      };
      quizData.forEach((qn: IQuiz) => {
        totalPointsByCategory.EI += qn.point.EI;
        totalPointsByCategory.SN += qn.point.SN;
        totalPointsByCategory.TF += qn.point.TF;
        totalPointsByCategory.JP += qn.point.JP;
      });
      let finalType = "";
      finalType += totalPointsByCategory.EI < 0 ? "E" : "I";
      finalType += totalPointsByCategory.SN < 0 ? "S" : "N";
      finalType += totalPointsByCategory.TF < 0 ? "T" : "F";
      finalType += totalPointsByCategory.JP < 0 ? "J" : "P";
      const choices: number[] = [];
      const sortedQuestion = [...quizData].sort(
        (a, b) => a.real_id - b.real_id
      );
      sortedQuestion.forEach((qn: IQuiz) => {
        choices.push(_.get(qn.point, qn.category));
      });
      // console.log("answers chosen are: ", choices);
      // console.log("prediction: ", finalType);
      // console.log("score for each category: ", _.get(LETTERS, finalType));

      const indexScores = computeIndexScores(totalPointsByCategory);
      // console.log(indexScores);
      toFinish({
        clientQuizAnswer: choices,
        clientQuizPdfKey: finalType,
        predictionClientQuiz: _.get(LETTERS, finalType),
        clientQuizPdfScores: indexScores,
      });
      return finalType;
    }
  };

  const handleRetakeQuiz = () => {
    setQuizData(quizQn.clientQuiz);
    setQuizPage(1);
  };
  const isNextButtonDisabled = useMemo(() => {
    const curQuestion = quizData.find((qn) => qn.id === quizPage);
    return curQuestion?.chosenAnswer === "" || quizPage === 20;
  }, [quizData, quizPage]);
  const qnsAnswered = useMemo(() => {
    return quizData.filter((qn) => qn.chosenAnswer !== "").length;
  }, [quizData]);
  return (
    <div className="flex flex-col">
      <div className="xs:px-20 px-10 mt-3 mb-8">
        <div className="max-w-[1392px] m-auto flex md:flex-row flex-col md:gap-44 gap-8">
          <div className="heading font-semibold text-primary-100">
            Hi, {quizInfo?.fullname}
          </div>
          <div className="grow">
            <QuizBriefing />
          </div>
        </div>
      </div>
      <ProgressBar curPage={quizPage} qnsAnswered={qnsAnswered} />
      <div className="m-auto xs:px-20 px-10 mt-20 max-w-[1392px] w-full">
        <div className="">
          <div className="w-full flex items-center justify-center">
            {/* <PagerLeft curPage={quizPage} pageProgress={() => setQuizPage(quizPage - 1)} location="SIDE"/> */}
            <div>
              {quizData
                .filter((item: IQuiz) => item.id === quizPage)
                .map((item: IQuiz, index: number) => {
                  return (
                    <AnimatePresence>
                      <QuizQuestion
                        qn={item}
                        chooseAnswer={(binaryValue: string) =>
                          handleChooseAnswer(item.id, binaryValue)
                        }
                      />
                    </AnimatePresence>
                  );
                })}
            </div>
            {/* <PagerRight disabled={isNextButtonDisabled} pageProgress={() => setQuizPage(quizPage + 1)} location="SIDE"/> */}
          </div>
        </div>
      </div>
      <div
        className={classNames([
          "flex items-center justify-center gap-5",
          { "mb-20": quizPage !== 20 },
          { "mb-10": quizPage === 20 },
        ])}
      >
        <PagerLeft
          curPage={quizPage}
          pageProgress={() => setQuizPage(quizPage - 1)}
        />
        <PagerRight
          disabled={isNextButtonDisabled}
          pageProgress={() => setQuizPage(quizPage + 1)}
        />
      </div>
      <div
        className={classNames([
          "flex gap-5 justify-center mb-20",
          { hidden: quizPage !== 20 },
        ])}
      >
        <ButtonNormal type="default" onClick={handleRetakeQuiz}>
          Restart
        </ButtonNormal>
        <ButtonNormal
          type="primary"
          onClick={handleShowResult}
          className={classNames({
            "cursor-not-allowed bg-neutral-100 hover:text-primary-400":
              qnsAnswered < 20,
          })}
          loading={finishing}
        >
          Finish
        </ButtonNormal>
      </div>
    </div>
  );
};

export default TakeQuiz;
