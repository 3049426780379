import React from 'react';
import styled from 'styled-components';
import variableStyles from '../../common/styles/variable-styles';
import { RoughNotation } from "react-rough-notation";
const Wrapper = styled.div`
  background-color: ${variableStyles.backgroundMain};
`;

const NotFoundPage: React.FC = () => {
  return (
    <Wrapper className="flex flex-col items-center justify-center w-full h-screen mx-auto">
      <div className='logo text-9xl'>
        404
      </div>
      <div className="flex flex-col items-center justify-center text-box">
        <h1 className="my-4 font-bold logo">Opps!</h1>
        <p className="mb-8 text-center logo">
          The page you&apos;re looking for could not be found.
        </p>
        <RoughNotation 
          type='circle' 
          animationDelay={100}
          animationDuration={2000}
          padding={25}
          show={true} 
          color={variableStyles.primaryInkGrey} 
          strokeWidth={2} 
          iterations={4}>
          <p className="mb-4 text-center logo text-secondary-100">Please Do Not Modify the URL</p>
        </RoughNotation>
      </div>
    </Wrapper>
  );
}

export default NotFoundPage;
