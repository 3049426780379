import React from "react";
import { Divider, Progress } from "antd";
import { StyledProgressBarInfo } from "./styled";
import variableStyles from "../../common/styles/variable-styles";
interface IProgressBar {
    qnsAnswered: number;
    curPage: number;
}
const ProgressBar: React.FC<IProgressBar> = ({qnsAnswered, curPage}) => {
    return (
        <div className="relative flex flex-col items-center">
            <Divider className="my-0 border-primary-100 border-opacity-20 absolute z-10 top-3"/>
            <Progress 
                className="relative z-50 w-10/12"
                showInfo={false}
                strokeColor={variableStyles.warning} 
                strokeWidth={3}
                percent={ curPage / 20 * 100}
                success={{
                    percent: qnsAnswered / 20 * 100, 
                    strokeColor: variableStyles.success
                }}
            />
            <div className="w-10/12 relative" >
                <StyledProgressBarInfo $qnsAnswered={qnsAnswered}>
                    {Math.round(qnsAnswered / 20 * 100 )}% Done
                </StyledProgressBarInfo>
            </div>
        </div>
    )
};

export default ProgressBar;