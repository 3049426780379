// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* IBM Plex Sans */
@font-face {
    font-family: 'IBM Plex Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'IBM Plex Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'IBM Plex Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'IBM Plex Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: 400;
  }`, "",{"version":3,"sources":["webpack://./src/common/styles/css/global-fonts.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,4BAA4B;IAC5B,+DAA8F;IAC9F,gBAAgB;EAClB;;EAEA;IACE,4BAA4B;IAC5B,+DAAkG;IAClG,gBAAgB;EAClB;;EAEA;IACE,4BAA4B;IAC5B,+DAAgG;IAChG,gBAAgB;EAClB;;EAEA;IACE,4BAA4B;IAC5B,+DAAiG;IACjG,gBAAgB;EAClB","sourcesContent":["/* IBM Plex Sans */\n@font-face {\n    font-family: 'IBM Plex Sans';\n    src: url('/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-Bold.ttf') format('truetype');\n    font-weight: 700;\n  }\n  \n  @font-face {\n    font-family: 'IBM Plex Sans';\n    src: url('/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-SemiBold.ttf') format('truetype');\n    font-weight: 600;\n  }\n  \n  @font-face {\n    font-family: 'IBM Plex Sans';\n    src: url('/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-Medium.ttf') format('truetype');\n    font-weight: 500;\n  }\n  \n  @font-face {\n    font-family: 'IBM Plex Sans';\n    src: url('/public/assets/fonts/font-text/IBMPlexSans/IBMPlexSans-Regular.ttf') format('truetype');\n    font-weight: 400;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
