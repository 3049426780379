import styled from 'styled-components';

import variableStyles from '../../../../../../common/styles/variable-styles';
import { rgba } from '../../../../../../common/utils/color';
import Icon, { IIconProps } from '../../../../../../components/Icon';
export const StyledBriefingItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 0 8px;
  flex: 1 0 calc(1 / 3 * 100%);
  border-right: 2px solid ${rgba(variableStyles.greyColor2, 0.2)};
  &:last-of-type {
    border-right: none;
  }
  @media screen and (max-width: 1200px) {
    border-right: none;
    border-bottom: 1px solid ${rgba(variableStyles.greyColor2, 0.2)};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    &:last-of-type {
      border-bottom: none;
    }
  }
`;

export const StyledIcon = styled(Icon)<IIconProps>`
    color: ${variableStyles.primaryInkGrey};
    font-size: 20px;
`;

export const StyledTextContainer = styled.div.attrs(props => ({
    className: 'caption-small',
}))`
`;