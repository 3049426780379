import React, { useEffect } from "react";
import ButtonNormal from "../../../../components/ButtonNormal";
import { usePostHog } from "posthog-js/react";
import QuizBriefingLanding from "./components/QuizBriefingLanding";
interface ILanding {
  takeQuiz: () => void;
  quizInfo: any;
}
const Landing: React.FC<ILanding> = ({ takeQuiz, quizInfo }) => {
  const posthog = usePostHog();

  useEffect(() => {
    if (quizInfo?.fullname) {
      posthog.identify(quizInfo?.fullname);
    }
  }, [posthog, quizInfo?.fullname]);

  return (
    <div className="px-10 pt-8">
      <div className="flex flex-col gap-6 max-w-[930px] m-auto items-center">
        <div className="heading font-semibold text-primary-100">
          Hi, {quizInfo?.fullname}
        </div>
        <div className="caption text-center flex flex-col gap-4">
          <p>
            Welcome to our Money Management Quiz! You will answer a series of
            questions to evaluate your money habits. Once you've completed the
            quiz, your responses will be compiled and shared with{" "}
            {quizInfo?.user?.fullname}.
          </p>
          <p>
            Assess your financial savvy and discover new insights about your
            expenses. Let's get started on the path to better financial
            understanding!
          </p>
        </div>
        <div></div>
        <div className="mt-6 w-full">
          <QuizBriefingLanding />
        </div>
        <div className="mt-8">
          <ButtonNormal
            type="primary"
            onClick={() => {
              posthog.capture("fiscal-quiz-started", {
                sent_to: quizInfo?.fullname,
                sent_by: quizInfo?.user?.fullname,
                url_code: quizInfo?.clientQuizCode,
              });
              takeQuiz();
            }}
          >
            <span className="button">START QUIZ</span>
          </ButtonNormal>
        </div>
      </div>
    </div>
  );
};

export default Landing;
