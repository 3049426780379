import type { ButtonProps } from 'antd';
import classNames from 'classnames';
import type { ReactElement } from 'react';
import React from 'react';

// * styled
import { Button } from './styled';

interface IButtonNormalProps extends ButtonProps {
  children?: ReactElement | string;
}

function ButtonNormal({
  className = '',
  children,
  ...rest
}: IButtonNormalProps) {
  return (
    <Button
      className={classNames('btn-normal typography button', {
        [className]: className,
      })}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default ButtonNormal;
