import type { ButtonProps } from 'antd';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';

import variableStyles from '../../common/styles/variable-styles';

const DEFAULT = {
  borderRadius: '4px',
  background: 'transparent',
  textColor: variableStyles.secondary,
  borderColor: variableStyles.secondary,

  hover: {
    textColor: variableStyles.secondary,
    borderColor: variableStyles.secondary,
  },

  active: {
    textColor: variableStyles.secondary,
    borderColor: variableStyles.secondary,
  },

  disabled: {
    textColor: variableStyles.greyColor1,
    borderColor: variableStyles.greyColor3,
  },
};

const PRIMARY = {
  background: variableStyles.secondary,
  textColor: variableStyles.primaryInkDark,
  borderColor: 'transparent',

  hover: {
    background: variableStyles.secondary,
    textColor: variableStyles.primaryInkWhite,
  },

  active: {
    background: variableStyles.secondary,
  },

  disabled: {
    textColor: variableStyles.greyColor1,
    background: variableStyles.greyColor3,
  },
};

const LINK = {
  background: 'transparent',
  textColor: variableStyles.primaryInkDark,
  borderColor: 'transparent',

  hover: {
    textColor: variableStyles.primaryInkDark,
    borderColor: 'transparent',
  },

  active: {
    textColor: variableStyles.primaryInkDark,
    borderColor: 'transparent',
  },

  disabled: {
    textColor: variableStyles.greyColor1,
    borderColor: 'transparent',
  },
};

const SIZES = {
  sm: '40px',
  md: '48px',
  lg: '52px',
};

export const Button = styled(AntButton)<ButtonProps>`
  &.ant-btn {
    border-radius: ${DEFAULT.borderRadius};
    height: ${SIZES.md};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    flex-flow: row-reverse;
    column-gap: 8px;

    &.ant-btn-icon-only {
      width: ${SIZES.md};
    }

    .anticon {
      &.anticon-loading {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:not(.ant-btn-icon-only) {
      .ant-btn-loading-icon {
        & > .anticon {
          margin-inline-end: 0px;
        }
      }
    }

    &:not(.ant-btn-icon-only) {
      & > .ant-btn-icon {
        &:not(:last-child) {
          margin-inline-end: 0px;
        }
      }
    }

    &.ant-btn-default {
      background: ${DEFAULT.background};
      border-color: ${DEFAULT.borderColor};
      color: ${DEFAULT.textColor};

      &:hover {
        border-color: ${DEFAULT.hover.borderColor};
        color: ${DEFAULT.hover.textColor};
      }

      &:active {
        border-color: ${DEFAULT.active.borderColor};
        color: ${DEFAULT.active.textColor};
      }

      &[disabled] {
        border-color: ${DEFAULT.disabled.borderColor};
        color: ${DEFAULT.disabled.textColor};
      }
    }

    &.ant-btn-primary {
      background: ${PRIMARY.background};
      border-color: ${PRIMARY.borderColor};
      color: ${PRIMARY.textColor};

      &:hover {
        background: ${PRIMARY.hover.background};
      }

      &:active {
        background: ${PRIMARY.active.background};
      }

      &[disabled] {
        color: ${PRIMARY.disabled.textColor};
        background: ${PRIMARY.disabled.background};
      }
    }

    &.ant-btn-link {
      background: ${LINK.background};
      border-color: ${LINK.borderColor};
      color: ${LINK.textColor};

      &:hover {
        border-color: ${LINK.hover.borderColor};
        color: ${LINK.hover.textColor};
      }

      &:active {
        border-color: ${LINK.active.borderColor};
        color: ${LINK.active.textColor};
      }

      &[disabled] {
        border-color: ${LINK.disabled.borderColor};
        color: ${LINK.disabled.textColor};
      }
    }
  }

  &.ant-btn-lg {
    height: ${SIZES.lg};

    &.ant-btn-icon-only {
      width: ${SIZES.lg};
    }
  }

  &.ant-btn-sm {
    height: ${SIZES.sm};

    &.ant-btn-icon-only {
      width: ${SIZES.sm};
    }
  }
`;
