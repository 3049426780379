import React from 'react';

import { StyledBriefingItemContainer, StyledIcon, StyledTextContainer} from './styled';
const QuizBriefingLanding: React.FC = () => {
  return (
    <div className="rounded-lg shadow-normal border border-neutral-200 border-opacity-20 sm:px-0 px-4">
      <div className="flex grow sm:flex-row flex-col">
        <StyledBriefingItemContainer>
          <div className="sm:mb-2 flex">
            <StyledIcon icon="icon-two-options" />
          </div>
          <div className="flex flex-col gap-2 items-start sm:items-center">
            <StyledTextContainer>For each question:</StyledTextContainer>
            <StyledTextContainer>Choose the best option</StyledTextContainer>
          </div>
        </StyledBriefingItemContainer>
        <StyledBriefingItemContainer>
          <div className="sm:mb-8 flex">
            <StyledIcon icon="icon-est-time" />
          </div>
          <StyledTextContainer>Takes about 3 minutes</StyledTextContainer>
        </StyledBriefingItemContainer>
        <StyledBriefingItemContainer>
          <div className="sm:mb-8 flex">
            <StyledIcon icon="icon icon-all-qns" />
          </div>
          <StyledTextContainer>
            You must answer all questions
          </StyledTextContainer>
        </StyledBriefingItemContainer>
      </div>
    </div>
  );
};

export default QuizBriefingLanding;
