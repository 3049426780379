import { createGlobalStyle } from "styled-components";
import variableStyles from "./variable-styles";

const GlobalStyle = createGlobalStyle`
    .logo {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_24};
        line-height: ${variableStyles.lineHeight_32};
        font-weight: ${variableStyles.fwBold};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkWhite};
    }
    .quiz-qn {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_24};
        line-height: ${variableStyles.lineHeight_24};
        font-weight: ${variableStyles.fwSemiBold};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkWhite};
    }

    .quiz-ans-container > div {
        transition: transform 0.2s ease-in-out;
    }

   

    .quiz-ans-container:hover > .quiz-ans {
        transform: translate(8px, -8px);
    }

    .quiz-ans {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_16};
        line-height: ${variableStyles.lineHeight_24};
        font-weight: ${variableStyles.fwMedium};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkWhite};
    }
    .caption {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_16};
        line-height: ${variableStyles.lineHeight_20};
        font-weight: ${variableStyles.fwNormal};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkGrey};
    }
    .caption-small {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_11};
        line-height: ${variableStyles.lineHeight_14};
        font-weight: ${variableStyles.fwNormal};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkGrey};
    }
    .button {
        font-family: ${variableStyles.fontButton};
        font-size: ${variableStyles.fontSize_16};
        line-height: ${variableStyles.lineHeight_24};
        font-weight: ${variableStyles.fwSemiBold};
        font-style: ${variableStyles.fsNormal};
        letter-spacing: ${variableStyles.letterSpacing_button};
    }
    .semantic {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_16};
        line-height: ${variableStyles.lineHeight_24};
        font-weight: ${variableStyles.fwMedium};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkWhite};
    }
    .heading {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_24};
        line-height: ${variableStyles.lineHeight_32};
        font-weight: ${variableStyles.fwNormal};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkWhite};
    }
    .basic {
        font-family: ${variableStyles.fontPrimary};
        font-size: ${variableStyles.fontSize_16};
        line-height: ${variableStyles.lineHeight_32};
        font-weight: ${variableStyles.fwNormal};
        font-style: ${variableStyles.fsNormal};
        color: ${variableStyles.primaryInkWhite};
        vertical-align: middle;
    }
`;
export { GlobalStyle };
