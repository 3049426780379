import { Icon as IconNify } from '@iconify/react';
import classNames from 'classnames';
import React from 'react';

export interface IIconProps {
  icon: string;
  className?: string;
  onClick?: () => void;
}

function Icon({
  icon = 'mdi:codepen',
  className,
  onClick,
  ...rest
}: IIconProps) {
  if (icon.includes(':')) {
    return (
      <IconNify
        {...rest}
        icon={icon}
        onClick={onClick}
        className={classNames('icon', {
          [className as string]: className,
        })}
      />
    );
  }
  return (
    <i
      {...rest}
      className={classNames('icon', {
        [icon]: icon,
        [className as string]: className,
      })}
      onClick={onClick}
    />
  );
}

export default Icon;
