import styled from "styled-components";
import variableStyles from "../../common/styles/variable-styles";

interface IStyledProgressBarInfo {
    $qnsAnswered: number;
}
export const StyledProgressBarInfo = styled.div<IStyledProgressBarInfo>`
    display: flex;
    position: absolute;
    left: ${(props) => `${props.$qnsAnswered / 20 * 100}%`};
    transform: translateX(-50%);
    color: ${variableStyles.success};
    font-weight: 600;
    transition: all ease-in-out 1s;
    
`