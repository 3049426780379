import React from "react";
import QuizAnswer from "./components/QuizAnswer";
import { motion } from "framer-motion";
interface IQuizQuestion {
    qn: IQuiz;
    chooseAnswer: (binaryValue: string) => void;
}
const QuizQuestion: React.FC<IQuizQuestion> = ({qn, chooseAnswer}) => {

    return (
        <motion.div 
            key={qn.id}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
            className="w-full px-8 text-primary-100 block">
            <div className="flex flex-col items-center gap-10">
                <div className="quiz-qn text-center">
                    {qn.id}. {qn.question}
                </div>
                <div className="flex flex-col items-center gap-6">
                    {
                        qn.options.map((opt, index) => 
                            {
                                return (
                                    <QuizAnswer 
                                        key={index}
                                        binaryValue={opt.binaryValue}
                                        answerDesc={opt.description} 
                                        chooseAnswer={() => chooseAnswer(opt.binaryValue)}
                                        chosenAnswer={qn.chosenAnswer}
                                    />
                                )
                            }
                        )
                    }
                </div>
            </div>
        </motion.div>
    )
};

export default QuizQuestion