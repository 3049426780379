const variableStyles = {
    fontButton: 'IBM Plex Sans',
    fontPrimary: 'Outfit',
    // Background colors
    backgroundHeader: '#05172C',
    backgroundMain: '#212B36',

    // Secondary colors
    secondary: '#C77F67',
    
    // Primary colors
    primaryInkWhite: '#FFF',
    primaryInkGrey: '#D9D9D9',
    primaryInkDark: '#212B36',

    // Grey colors
    greyColor1: '#737373',
    greyColor2: '#EFEFEF',
    greyColor3: '#D9D9D9',
    greyColor4: '#F4F4F4',
    greyColor5: '#F1F1F1',
    greyColor6: '#6D7B88',
    greyColor7: '#666666',
    greyColor8: '#F9F6F5',
    greyColor9: '#637381',
    greyColor10: '#929292',
    greyColor11: '#FBFAF9',

    // Semantic colors
    success: '#4CAF50',
    warning: '#FFB300',

    // Font sizes
    fontSize_24: '24px',
    fontSize_16: '16px',
    fontSize_14: '14px',
    fontSize_11: '11px',

    // Font Style
    fsNormal: 'normal',

    // Font weights
    fwLight: 300,
    fwNormal: 400,
    fwMedium: 500,
    fwSemiBold: 600,
    fwBold: 700,

    // Line heights
    lineHeight_24: '24px',
    lineHeight_32: '32px',
    lineHeight_20: '20px',
    lineHeight_14: '14px',

    // letter spacings
    letterSpacing_button: '0.05px',
}

export default variableStyles;